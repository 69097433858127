/*
 * @Author: your name
 * @Date: 2020-09-07 17:48:52
 * @LastEditTime: 2020-09-18 10:50:22
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\venueMana\physique\indexClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
    constructor(type) {
        super()
        if (type === 'form') {
            // this.categoryId = ''
        }
    }
}
export default searchFrom
